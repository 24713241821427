import React from 'react';

import {
  BtnsNext,
  Lead,
} from '../components';

const NotFoundPage = () => (
  <React.Fragment>
    <Lead content="NOT FOUND" />
    <Lead content="Sorry, the page you were looking for could not be found." />
    <BtnsNext
      buttons={[{
        link: '/',
        text: 'Go to home page',
      }]}
      narrow
    />
  </React.Fragment>
);

export default NotFoundPage;
